<template>
  <div class="left-content">
    <div class="array-type" v-if="btnShow">
      <p class="type-title">排列方式：</p>
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon2.png'"
        v-show="!single"
        title="单个展示"
        @click="changeType(0)"
      />
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon1-act.png'"
        v-show="single"
      />
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon1.png'"
        title="平铺展示"
        v-show="single"
        @click="changeType(1)"
      />
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon2-act.png'"
        v-show="!single"
      />
    </div>
    <div class="main-left" v-show="single" v-blockLoadMore="loadMoreVideoData">
      <div
        class="main-items"
        :class="i === myItemsIndex ? 'active' : ''"
        v-for="(t, i) in lists"
        :key="t.id"
        @click="changeItems(i, t.id, t.alias, t.sizeType)"
      >
        <img :src="config.img" />
        <span class="main-msg">{{ t.title }}</span>
      </div>
    </div>

    <div class="cycle" v-if="farmID === 59 && cycleList.length">
      <div class="cycle-title">生长条件</div>
      <div class="cycle-list">
        <div
          class="cycle-items"
          v-for="(item, index) in cycleList"
          :key="index"
        >
          <p class="cycle-items-title">{{ item.title }}</p>
          <!-- <p class="cycle-items-brief">{{ item.brief }}</p> -->
          <p class="cycle-items-brief" v-html="item.brief"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "pageleft",
  props: {
    btnShow: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
    },
    nowTitle: {
      type: String,
      default: "",
    },
    lists: {
      type: Array,
      default: () => [],
    },
    cycleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myItemsIndex: 0,
      single: true,
    };
  },
  watch: {
    nowTitle(newVal) {
      this.myItemsIndex = this.lists.findIndex((t) => t.alias == newVal);
    },
  },
  created() {
    this.myItemsIndex = 0;
  },
  computed: {
    ...mapState({
      equipmentName: (state) => state.user.equipmentName,
      farmID: (state) => state.user.farmID,
    }),
  },
  methods: {
    loadMoreVideoData() {
      this.$emit("loadMoreVideoData");
    },
    changeType(index) {
      if (index) {
        this.single = false;
        this.$emit("changeAll", 1);
      } else {
        this.single = true;
        this.$emit("changeItems", { id: this.lists[0].id, type: 0 });
      }
    },

    changeItems(i, id, alias, sizeType) {
      this.myItemsIndex = i;
      this.$emit("changeItems", { alias, id, type: 0, sizeType });
    },
  },
};
</script>

<style lang="less" scoped>
.left-content {
  width: 487px;
  height: 800px;
  background: #0e2138;
  display: flex;
  flex-direction: column;
  padding: 49px 50px;
  margin: 0 20px 0 0;
  .array-type {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .type-title {
      font-size: 16px;
      margin-right: 10px;
    }
    img {
      cursor: pointer;
      margin-right: 10px;
    }
    .type-icons {
      width: 30px;
      height: 30px;
    }
  }
  .main-left {
    width: 100%;
    min-height: 56px;
    max-height: 500px;
    padding: 0 10px 0 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #3e90e5;
    }

    .main-items {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 25px;
      height: 38px;
      margin-bottom: 18px;
      background: #0e2f53;
      cursor: pointer;

      &:hover {
        background: #3e90e5;
      }

      &.active {
        background: #3e90e5;
      }

      img {
        width: 27px;
        height: 20px;
        margin-right: 10px;
      }

      .main-msg {
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .cycle {
    width: calc(100% - 10px);
    height: 95%;
    background-color: #1c3d65;
    padding: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #3e90e5;
    }
    .cycle-title {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      position: relative;
      padding: 0 16px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 100%;
        border-radius: 5px;
        background-color: #3e90e5;
      }
    }
    .cycle-list {
      width: 100%;
      padding: 24px 0;
      .cycle-items {
        width: 100%;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #eee;
        &:hover {
          .cycle-items-title {
            color: #3e90e5;
          }
        }
        &:last-child {
          border: none;
        }
        .cycle-items-title {
          width: 100%;
          margin-bottom: 6px;
          font-size: 16px;
          color: #fff;
          position: relative;
          padding: 0 16px;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;

            border-radius: 50%;
            background-color: #3e90e5;
          }
        }
        .cycle-items-brief {
          width: 100%;
          font-size: 14px;
          color: #f1f1f1;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
